const qs = sel => document.querySelector(sel);
const qsa = sel => document.querySelectorAll(sel);
const APP = {
	name: 'iBrush HTML Starter'
};
$(function() {
    svg4everybody();

    new WOW().init();

    $('a[href^="#"]').on('click', function() {
        $('html, body').animate({
            scrollTop: $( $.attr(this, 'href') ).offset().top
        }, 500);

        return false;
    });
});
